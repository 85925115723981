import React from "react";
import { Form } from "antd";

import { metaFields } from "utils";
import { LocationFileOrUpload } from "components/location";


const LocationMenusForm = ({ location }) => {

    const { normalize, getValue } = metaFields;
    const meta = normalize( location.meta_fields );

    const [ form ] = Form.useForm();

    const formLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    }


    return (
        <Form
            name="location-menus"
            form={ form }
            {...formLayout}
        >
            <LocationFileOrUpload
                value={ getValue(meta, "menu_main") }
                type={ "file" }
                buttonText={ "Add Speisekarte" }
                metaFields={ location.meta_fields }
                accept={ "*/*" }
                variables={{
                    input : {
                        location_id: location.id,
                        meta_type: "file",
                        meta_key: "menu_main",
                        meta_group : "menus"
                    }
                }}
            />
            <LocationFileOrUpload
                value={ getValue(meta, "menu_deserts") }
                type={ "file" }
                metaFields={ location.meta_fields }
                accept={ "*/*" }
                buttonText={ "Add Deserts" }
                variables={{
                    input : {
                        location_id: location.id,
                        meta_type: "file",
                        meta_key: "menu_deserts",
                        meta_group : "menus"
                    }
                }}
            />
            <LocationFileOrUpload
                value={ getValue(meta, "menu_bar") }
                type={ "file" }
                metaFields={ location.meta_fields }
                buttonText={ "Add Getränkekarte" }
                accept={ "*/*" }
                variables={{
                    input : {
                        location_id: location.id,
                        meta_type: "file",
                        meta_key: "menu_bar",
                        meta_group : "menus"
                    }
                }}
            />
            <LocationFileOrUpload
                value={ getValue(meta, "menu_vine") }
                type={ "file" }
                metaFields={ location.meta_fields }
                accept={ "*/*" }
                buttonText={ "Add Weinkarte" }
                variables={{
                    input : {
                        location_id: location.id,
                        meta_type: "file",
                        meta_key: "menu_vine",
                        meta_group : "menus"
                    }
                }}
            />
        </Form>
    );
};

export default LocationMenusForm;