import React from "react";
import { useLocation } from "react-router-dom";

import { Forms } from "components/auth";
import { useVarParam } from "components/use-hooks";
import { ContainerWrap } from "components/wraps";


const LoginPage = () => {

    const location = useLocation(),
          guestParams = useVarParam("guest");

    if(location.state){
        guestParams.set({ "comeFrom" : location.state.path } );
    }

    return(
        <ContainerWrap className="page">
            <div className="block">
                <Forms.SignIn />
            </div>
        </ContainerWrap>
    );
};

export default LoginPage;