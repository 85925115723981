import { gql } from '@apollo/client';


export const GET_VISITS = gql`
    query GetVisits(
        $searchText : String
        $perPage: Int
        $page: Int
        $locationId : ID!
        $orderBy : [QueryVisitsOrderByOrderByClause!]
        $where : QueryVisitsWhereWhereConditions
    ){
        visits(
            text: $searchText
            first : $perPage
            page : $page
            location_id : $locationId
            orderBy : $orderBy
            where : $where
        ){
            paginatorInfo {
                currentPage
                hasMorePages
                total
                perPage
            }
            data{
                id
                user_id
                first_name
                last_name
                email
                phone
                covid_id
                number_guests
                covid{
                    id
                    location_id
                    email
                    type
                    status
                    message
                    moderator_id
                    moderator_notice
                    created_at
                    updated_at
                    moderator{
                        id
                        first_name
                        last_name
                        status
                        role
                    }
                }
                place{
                    id
                    title
                }
                created_at
            }
        }
    }
`;