import React from "react";
import { Button } from "antd";

import CPForms from "./form-part";
import { StepsNav } from "components/service";
import { useVarParam } from "components/use-hooks";


const CompanyProfileStepForm = ({ formParams, formSubmit, loading }) => {

    const appParams = useVarParam();

    const onNextStep = () => {
        appParams.set({
            "companyProfile" : {
                ...appParams.get("companyProfile"),
                ...formParams.form.getFieldsValue()
            }
        })
    };

    return(
        <>
            <StepsNav
                varParams={ appParams }
                inMainHeader={ true }
                stateName={ "companyProfile" }
                backOnFirstStep={ false }
                onNextStep={ onNextStep }
                onFinalStep={
                    () => {
                        onNextStep();

                        const { step, ...values } = appParams.get("companyProfile");
                        formSubmit( values );
                    }
                }
            >
                <CPForms.GeneralInfo
                    title={ <h1>General info</h1> }
                    { ...formParams }
                />
                <CPForms.ContactInfo
                    title={ <h1>Contact info</h1> }
                    { ...formParams }
                />
                <CPForms.AddressInfo
                    title={ <h1>Address info</h1> }
                    { ...formParams }
                />
            </StepsNav>
            <div className="form-actions">
                <Button
                    type="primary"
                    loading={ loading }
                    disabled={ loading }
                    onClick={ () => formParams.form.submit() }
                >
                    Weiter
                </Button>
            </div>
        </>
    );
};

export default CompanyProfileStepForm;