import React from "react";
import { Form, Input } from "antd";

import { FloatLabel } from "components/service";
import { metaFields } from "utils";


const LUAddressInfo = ({ form, formLayout, location,...props }) => {

    const { normalize, getValue } = metaFields;
    const meta = normalize(location.meta_fields);

    return (
        <>
            <Form
                name="location-address-info"
                form={ form }
                {...formLayout}
                onFinish={
                    ( values ) => {
                        if( props.submitAction) {
                            props.submitAction( values );
                        }
                    }
                }
            >
                <div className="field-group">
                    <Form.Item
                        name="location_city-string--address"
                        initialValue={ getValue( meta, "location_city") }
                        rules={[
                            {
                                required: true, message: 'Field required'
                            }
                        ]}
                    >
                        <FloatLabel label="City*">
                            <Input name="location_city" />
                        </FloatLabel>
                    </Form.Item>
                    <Form.Item
                        name="location_zip-string--address"
                        initialValue={ getValue( meta, "location_zip") }
                        rules={[
                            {
                                required: true, message: 'Field required'
                            }
                        ]}
                    >
                        <FloatLabel label="ZIP*">
                            <Input name="location_zip" />
                        </FloatLabel>
                    </Form.Item>
                </div>
                <div className="field-group">
                    <Form.Item
                        name="location_street-string--address"
                        initialValue={ getValue( meta, "location_street") }
                        rules={[
                            {
                                required: true, message: 'Field required'
                            }
                        ]}
                    >
                        <FloatLabel label="Street">
                            <Input name="location_street" />
                        </FloatLabel>
                    </Form.Item>
                    <Form.Item
                        name="location_number-string--address"
                        initialValue={ getValue( meta, "location_number") }
                    >
                        <FloatLabel label="Number">
                            <Input name="location_number" />
                        </FloatLabel>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
};

export default LUAddressInfo;