const saveAsImage = (url, fileName) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const a  = document.createElement('a');

    let base_image = new Image();
    base_image.src = url;
    base_image.crossOrigin="anonymous";

    base_image.onload = function() {

        canvas.width = base_image.width;
        canvas.height = base_image.height;

        ctx.drawImage(base_image, 0, 0);

        a.href = canvas.toDataURL('image/png');
        a.download = `${fileName}.png`;

        a.click();

        canvas.remove();
        a.remove();

    }
}

const saveDocument = (url) => {

    const link = document.createElement('a');

    link.href = url;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

}

const saveAsFile = {
    document: saveDocument,
    image : saveAsImage
}


export default saveAsFile;