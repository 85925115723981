import React from "react";


import { LocationsGrid } from "components/location";
import { LocationsPageHeader } from "components/location";
import { ContainerWrap } from "components/wraps";



const LocationsPage = () => {

    return(
        <ContainerWrap className="page locations-page">
            <LocationsPageHeader />
            <LocationsGrid />
        </ContainerWrap>
    )
};

export default LocationsPage;