import React from "react";
import { useMutation,  } from "@apollo/client";
import { Button } from "antd";

import { COMPANY_CREATE } from "graphql/mutation/company-gql";

import { errorNotification, successNotification } from "components/request-result";


const CompanyCreate = ({ me }) => {

    const [ createCompany, { loading }] = useMutation( COMPANY_CREATE,
        {
            update(cache, { data }) {

                const { label, message, company } = data.companyCreate;

                cache.modify({
                    id: cache.identify(me),
                    fields: {
                        company(){
                            return company;
                        },
                        company_id(){
                            return company.id;
                        }
                    },
                });

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );


    return(
        <div className="company-create">
            <h1>Create company</h1>
            <p>You are not the owner of the company.</p>
            <p>To become one, click the button below, then fill out the form</p>
            <Button
                type="primary"
                loading={ loading }
                disabled={ loading }
                onClick={ () => createCompany() }
            >
                Create
            </Button>
        </div>
    );
}

export default CompanyCreate;