import React from "react";
import { Link } from "react-router-dom";

import MainMenu from "../main-menu";
import LegalMenu from "../legal-menu";
import MenuDrawer from "../menu-drawer";
import Icons from "components/icons";
import { useVarParam } from "components/use-hooks";
import { ContainerWrap } from "components/wraps";


const MainMenuAppBar = () => {

    const appParams = useVarParam(),
          LeftControl = () => appParams.get("leftHeaderControl"),
          RightControl = () => appParams.get("rightHeaderControl");

    return (
        <header
            className={`main-menu-bar ${ appParams.get().headerClass.join(" ") }`}
        >
            <ContainerWrap>
                <div className="left-side">
                    { !appParams.get("leftHeaderControl") &&
                        <Link
                            to="/"
                            className="menu-button home"
                        >
                            <Icons.Logo className="logo"/>
                        </Link>
                    }
                    <LeftControl />
                </div>
                <div className="right-side">
                    <RightControl/>
                    { !appParams.get("rightHeaderControl") &&
                        <MenuDrawer
                            varParam={ appParams }
                        >
                            <MainMenu.App key="guest-main-menu" />
                            <LegalMenu  key="legal-menu"/>
                        </MenuDrawer>
                    }
                </div>
            </ContainerWrap>
        </header>
    );
};


export default MainMenuAppBar;