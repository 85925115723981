import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";

import { FloatLabel } from "components/service";


const LocationVisitsFilterDateRange = ({ filterData, setFilterData }) => {

    const [ dateRange , setDateRange ] = useState({
        startDate: null,
        endDate: null,
        // endOpen: false
    });

    useEffect( () => {

        const { startDate, endDate } = dateRange;

        if( startDate && endDate && !filterData.createDate.value){
            console.log(dateRange)

            setFilterData({
                ...filterData,
                createDate: {
                    column: "CREATED_AT",
                    operator: "BETWEEN",
                    value: [
                        startDate.format("YYYY-MM-DD 00:00:00"),
                        endDate.format("YYYY-MM-DD 23:59:59")
                    ]

                }
            })
        }

    }, [ dateRange, filterData, setFilterData ]);

    return(
        <>
            <FloatLabel
                label="From"
                float={ true }
            >
                <DatePicker
                    disabledDate={ ( startValue  ) => {
                        const { endDate } = dateRange ;
                        if (!startValue || !endDate) {
                            return false;
                        }
                        return startValue.valueOf() > endDate.valueOf();
                    }}
                    format="YYYY-MM-DD"
                    value={ dateRange.startDate }
                    onChange={ (value) => {
                        setDateRange( {
                            ...dateRange,
                            startDate : value,
                        })

                        if( !value ){
                            setFilterData({
                                ...filterData,
                                createDate: {}
                            })
                        }

                    }}
                    // onOpenChange={ ( open ) => {
                    //     if(!open){
                    //         setDateRange( {
                    //             ...dateRange,
                    //             endOpen : true,
                    //         })
                    //     }
                    // }}
                />
            </FloatLabel>
            <FloatLabel
                label="To"
                float={ true }
            >
                <DatePicker
                    disabledDate={ ( endValue   ) => {
                        const { startDate } = dateRange ;
                        if (!endValue  || !startDate) {
                            return false;
                        }
                        return  endValue.valueOf() <= startDate.valueOf();
                    }}
                    format="YYYY-MM-DD"
                    value={ dateRange.endDate }
                    // open={ dateRange.endOpen }
                    onChange={ (value) => {

                        setDateRange( {
                            ...dateRange,
                            endDate : value,
                        })
                        if( !value ){
                            setFilterData({
                                ...filterData,
                                createDate: {}
                            })
                        }
                    }}
                    // onOpenChange={ ( open ) => {
                    //     setDateRange( {
                    //         ...dateRange,
                    //         endOpen : open,
                    //     })
                    // }}
                />
            </FloatLabel>
        </>
    )
};

export default LocationVisitsFilterDateRange;