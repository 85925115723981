import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { VISITS_BY_LOCATION_EXPORT } from "graphql/mutation/visits-gql";

import { errorNotification, successNotification } from "components/request-result";
import { saveAsFile } from "utils";


const LocationVisitsExportButton = ( { searchText, locationId, filters } ) => {

    const [visitsExport, { loading }] = useMutation( VISITS_BY_LOCATION_EXPORT,
        {
            update(cache, { data }) {

                const {
                    visitsExport : {
                        label,
                        message,
                        file
                    }
                } = data;

                saveAsFile.document(file);

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <Button
            type="primary"
            loading={ loading }
            onClick={() => {
                visitsExport({
                    variables: {
                        text: searchText,
                        locationId,
                        where: { ...filters }
                    }
                }).catch( ( error ) => {
                    errorNotification( error );
                });
            }}
        >
            Export XLS
        </Button>
    );
}

export default LocationVisitsExportButton;