import MainMenuAppBar from "./main-menu-app-bar";

import "./main-menu-bar.scss";


const MainMenuBar = {
    App: MainMenuAppBar
}


export default MainMenuBar;