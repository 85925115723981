import { HomeAppPage } from "./home";
import { LoginPage, RegistrationPage, ForgotPasswordPage } from "./authorization";
import CompanyPages from "./company";
import LocationPages from "./location";
import Page404 from "./404/404-page";

import "./pages.scss";


export const Pages = {
    HomeAppPage,
    CompanyPages,
    LocationPages,
    Page404
};

export const GuestPages = {
    LoginPage,
    RegistrationPage,
    ForgotPasswordPage,
}