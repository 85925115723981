import React from "react";

import { Forms } from "components/user";


const CompanyMyProfileSubPage = ( { me } ) => {

    return(
        <div className="company-my-profile-sub-page">
            <h1>My profile</h1>
            <p>Login: { me.email }</p>
            <Forms.Profile
                me={ me }
            />
        </div>
    );
}

export default CompanyMyProfileSubPage;