import React from "react";
import { useMutation } from "@apollo/client";

import { LOCATION_META_UPLOAD, LOCATION_META_DELETE } from "graphql/mutation/location-gql";

import { locationUploadTypes } from "./type";
import { successNotification } from "components/request-result";

import "./location-file-or-upload.scss"


const LocationFileOrUpload = ({ metaFields, variables, ...props }) => {

    const [metaUpload, { loading }] = useMutation(LOCATION_META_UPLOAD,
        {
            update(cache, { data }) {

                const {
                    locationMetaUpload : {
                        label,
                        message,
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });
            }
        });

    const [metaDelete, { loading : deleteLoading }] = useMutation(LOCATION_META_DELETE,
        {
            variables: {
                meta_key: variables.input.meta_key,
                meta_model_id: parseInt(variables.input.location_id)
            },
            update(cache, { data }) {

                const {
                    metaDelete : {
                        label,
                        message,
                    }
                } = data;

                cache.evict({
                    id: cache.identify( metaFields.find( field => field.meta_key === variables.input.meta_key ) )
                });
                cache.gc();

                successNotification({
                    title: label,
                    description: message
                });
            }
        });

    const LocationFileOrUpload = locationUploadTypes[props.type];

    return(
        <LocationFileOrUpload
            uploadMutation={ metaUpload }
            loading={ loading }
            deleteMutation={ metaDelete }
            deleteLoading={ deleteLoading }
            variables={ variables }
            { ...props }
        />
    );

};

export default LocationFileOrUpload;