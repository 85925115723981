import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom';

import { Pages } from "components/pages";
import { checkUser } from "utils";


const { isAdmin, hasActiveCompany } = checkUser;

const NoCompanyRoutes = ({ me }) => {

    const baseRoute = "/company/",
        { CompanyPages } = Pages;

    return (
        <Switch>
            { isAdmin(me.role) &&
            <Route path={ `${ baseRoute }select` } exact>
                <CompanyPages.Select me={ me } />
            </Route>
            }
            { !isAdmin(me.role) && !hasActiveCompany(me.company) &&
            <Route path={ `${ baseRoute }create` } exact>
                <CompanyPages.Create me={ me } />
            </Route>
            }
            <Route path="*">
                <Redirect
                    to={ isAdmin(me.role) ? `${baseRoute}select` : `${baseRoute}create` }
                />
            </Route>
        </Switch>
    )
}


export default NoCompanyRoutes;