import LocationMenuSubPage from "./location-menu-sub-page";
import LocationProfileSubPage from "./location-profile-sub-page";
import LocationVisitsSubPage from "./location-visits-sub-page";



const locationSubPage = {
    Profile           : LocationProfileSubPage,
    Menus             : LocationMenuSubPage,
    Visits            : LocationVisitsSubPage
}


export default locationSubPage;