import React from "react";

import { useMe } from "components/use-hooks";
import { ContainerWrap } from "components/wraps";


const HomeAppPage = () => {

    const { me } = useMe();

    return(
        <ContainerWrap className="page home-page">
            <div className="block sml">
                <div className="row">
                    <h1>Hallo { me.first_name }</h1>
                </div>
            </div>
        </ContainerWrap>
    )
};

export default HomeAppPage;