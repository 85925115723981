import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import { USER_REGISTRATION } from "graphql/mutation/user-gql";

import RegistrationSteps from "./steps";
import { StepsNav } from "components/service";
import { useVarParam } from "components/use-hooks";
import { errorNotification } from "components/request-result";


const RegistrationForm = () => {

    const [ success, setSuccess ] = useState(false);
    const guestParams = useVarParam("guest");

    const [ registrationRequest, { loading } ] = useMutation(
        USER_REGISTRATION,
        {
            update() {

                setSuccess(true);

            },
            onError(error){
                errorNotification(error);
            }
        }
    );


    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    return(
        <div className="registration-form">
            { success ?
                <RegistrationSteps.Success  success={ success } />:
                <StepsNav
                    varParams={ guestParams }
                    stateName="registration"

                >
                    <RegistrationSteps.UserIntroduce
                        formItemLayout={formItemLayout}
                    />
                    <RegistrationSteps.LoginData
                        formItemLayout={formItemLayout}
                    />
                    <RegistrationSteps.LegalNotice
                        registrationRequest={ registrationRequest }
                        loading={ loading }
                    />
                </StepsNav>
            }
        </div>
    );
}

export default RegistrationForm;