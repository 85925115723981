import React from "react";

import { Forms } from "components/location";


const LocationProfileSubPage = ({ location }) => {

    return(
        <div className="location-profile-sub-page">
            <h1>Location profile</h1>
            <Forms.Update location={ location } />
        </div>
    );
}

export default LocationProfileSubPage;