import { gql } from '@apollo/client';


export const LOCATION_CREATE = gql`
    mutation LocationCreate(
        $type : LocationType!
        $input : LocationCreateOrUpdateInput
    ) {
        locationCreate(
            type : $type
            input : $input
        ){
            label
            message
            location{
                id
                title
                company_id
                type
                status
                logo
                preview_banner
                latitude
                longitude
                slug
                qrcode
                meta_fields{
                    id
                    location_id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                visitsPerMonth{
                    count
                    month
                }
                visitsDangerous
                start_at
                end_at
                created_at
                updated_at
            }
        }
    }
`;

export const LOCATION_UPDATE = gql`
    mutation LocationUpdate(
        $id : ID!
        $locationInput : LocationCreateOrUpdateInput
        $metaInput : [LocationMetaInput]
    ) {
        locationUpdate(
            id: $id 
            input : $locationInput
        ){
            label
            message
        }
        locationMetaSet(
            location_id: $id
            input : $metaInput
        ){
            label
            message
            location{
                id
                title
                company_id
                type
                status
                logo
                preview_banner
                latitude
                longitude
                slug
                qrcode
                meta_fields{
                    id
                    location_id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                visitsPerMonth{
                    count
                    month
                }
                visitsDangerous
                start_at
                end_at
                created_at
                updated_at
            }
        }
    }
`;


export const LOCATION_META_UPLOAD = gql`
    mutation LocationMetaUpload(
        $input : LocationUploadFileInput
    ) {
        locationMetaUpload(
            input : $input
        ){
            label
            message
            location{
                id
                logo
                preview_banner
                meta_fields{
                    id
                    location_id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
            }
        }
    }
`;

export const LOCATION_META_DELETE = gql`
    mutation LocationMetaDelete(
        $meta_model_id: ID!
        $meta_key: String
    ) {
        metaDelete(
            meta_model: location
            meta_model_id: $meta_model_id
            meta_key: $meta_key
        ){
            label
            message
        }
    }
`;
