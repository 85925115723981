import React, {useEffect, useState} from "react";
import { Select, Checkbox } from "antd";

import LocationVisitsExportButton from "../location-visits-export-button";
import LocationVisitsFilterSearch from "./location-visits-filter-search";
import LocationVisitsFilterDateRange from "./location-visits-filter-daterange";
import { FloatLabel } from "components/service";
import { whereConditionBuilder } from "utils";

const { Option } = Select;


const LocationVisitsFilter = ({ setSearchText, searchText, setFilters, locationId  }) => {

    const [ filterData, setFilterData ] = useState({
              userType : {},
              createDate : {},
              covid : {}
          });


    useEffect( () => {
        setFilters(whereConditionBuilder(filterData));
    }, [ filterData, setFilters ] );


    return(
        <div className="location-visits-filter">
            <div className="row">
                <div className="search-input-wrap ant-form">
                    <LocationVisitsFilterSearch
                        setSearchText={ setSearchText }
                    />
                </div>
                <div className="export-button-wrap">
                    <LocationVisitsExportButton
                        locationId={ locationId }
                        searchText={ searchText }
                        filters={ whereConditionBuilder(filterData) }
                    />
                </div>
            </div>
            <div className="row">
                <div className="user-type-wrap ant-form">
                    <FloatLabel
                        label="User type"
                        float={ true }
                    >
                        <Select
                            name="user_type"
                            defaultValue={ "all" }
                            onChange={ ( value ) => {

                                if( value !== 'all' ){

                                    setFilterData( {
                                        ...filterData,
                                        userType : {
                                            column: "USER_ID",
                                            operator: value
                                        },
                                    })

                                } else {

                                    setFilterData( {
                                        ...filterData,
                                        userType : {},
                                    })

                                }

                            }}
                        >
                            <Option value="all">All</Option>
                            <Option value="IS_NOT_NULL">Registered</Option>
                            <Option value="IS_NULL">Guests</Option>
                        </Select>
                    </FloatLabel>
                </div>
                <div className="covid-wrap">
                    <Checkbox
                        onChange={ ( e ) => {

                            if( e.target.checked){

                                setFilterData( {
                                    ...filterData,
                                    covid : {
                                        column: "COVID_ID",
                                        operator: "IS_NOT_NULL"
                                    },
                                })

                            } else {

                                setFilterData( {
                                    ...filterData,
                                    covid : {},
                                })

                            }

                        }}
                    >
                        Covid
                    </Checkbox>
                </div>
                <div className="data-range-wrap ant-form">
                    <LocationVisitsFilterDateRange
                        filterData={ filterData }
                        setFilterData={ setFilterData }
                    />
                </div>
            </div>
        </div>
    );
}

export default LocationVisitsFilter;