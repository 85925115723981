import CompanyPage from "./company-page";
import companySubPage from "./sub-page";


const CompanyPages = {
    Page : CompanyPage,
    ...companySubPage
}


export default CompanyPages;