import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom';

import { Pages } from "components/pages";
import { checkUser } from "utils";


const { isAdmin } = checkUser;

const CompanyRoutes = ({ me }) => {

    const baseRoute = "/company/",
          { CompanyPages } = Pages;

    return (
        <Switch>
            <Route path={ `${ baseRoute }` } exact>
                <CompanyPages.Edit me={ me } />
            </Route>
            <Route path={ `${ baseRoute }users` } exact>
                <h1 className="color-active">Page in progress</h1>
            </Route>
            <Route path={ `${ baseRoute }my-profile` } exact>
                <CompanyPages.MyProfile me={ me } />
            </Route>
            <Route path={ `${ baseRoute }change-password` } exact>
                <CompanyPages.ChangePassword />
            </Route>
            { isAdmin(me.role) &&
                <Route path={ `${ baseRoute }select` } exact>
                    <CompanyPages.Select
                        me={ me }
                        internal
                    />
                </Route>
            }
            <Route path="*">
                <Redirect to={ baseRoute } />
            </Route>
        </Switch>
    )
}


export default CompanyRoutes;