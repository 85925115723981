import { InMemoryCache, makeVar } from "@apollo/client";


const cache = new InMemoryCache({
    typePolicies: {
        Location: {
            fields: {
                meta_fields: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                }
            },
        },
        Query: {
            fields: {
                guestParams: {
                    read () {
                        return guestParamsVar();
                    }
                },
                appParams: {
                    read () {
                        return appParamsVar();
                    }
                }
            },
        },
    },
});

export const guestParamsInitial = {
    mainBlockClass: [],
    appBlockClass: [],
    registration : {}
};

export const guestParamsVar = makeVar(guestParamsInitial);

export const appParamsInitial = {
    headerClass : [],
    mainBlockClass: [],
    appBlockClass: [],
    leftHeaderControl: false,
    rightHeaderControl: false,
    companyProfile: {}
};

export const appParamsVar = makeVar(appParamsInitial);

export default cache;