import React from "react";
import { Link } from "react-router-dom";
import { WarningOutlined } from "@ant-design/icons";

import LocationBanner from "../location-banner";
import { AvatarBlock } from "components/avatar";

import Icons from "components/icons";


const LocationsGridItem = ({ location } ) => {

    const {
        visitsPerMonth,
        preview_banner,
        visitsDangerous,
        logo
    } = location;


    return(
        <div className={ `locations-grid-item ${ visitsDangerous > 0 ? "alert" : "" }` }>
            <Link className="grid-header" to={`/locations/${ location.id }`}>
                { visitsDangerous > 0 &&
                    <div className="alert-notification">
                    <WarningOutlined /> { visitsDangerous } Hinweise
                    </div>
                }
                <LocationBanner
                    image={ preview_banner }
                    classname={ location.status  }
                    noImage={ "No location banner" }
                />
                <AvatarBlock
                    size={ 50 }
                    image={ logo }
                    badge={<>No logo</>}
                >
                    <h4 className="text-white">{ location.title }</h4>
                    <p className={ `status ${ location.status }`}>{ location.status }</p>
                </AvatarBlock>
            </Link>
            <div className="grid-data">
                <div className="visits">
                     <Icons.Peoples />
                    <span>{ visitsPerMonth[0].month } <b>{ visitsPerMonth[0].count }</b></span>
                </div>
            </div>
        </div>
    );
}

export default LocationsGridItem;