import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Form, Input } from "antd";

import { USER_UPDATE } from "graphql/mutation/user-gql";
import { GET_ME } from "graphql/query/user-gql";

import { FloatLabel } from "components/service";
import { errorNotification, successNotification } from "components/request-result";



const UserProfileForm = ({ me, action = () => {}} ) => {

    const [ form ] = Form.useForm();

    const [userUpdateRequest, { loading }] = useMutation( USER_UPDATE,
        {
            update(cache, { data }) {

                const {
                    userUpdate : {
                        label,
                        message,
                        user
                    }
                } = data;

                cache.writeQuery(  {
                    query: GET_ME,
                    data: {
                        me: {
                            ...me, ...user
                        }
                    }
                });

                action();

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    return(
        <Form
            className="my-profile-form"
            form={ form }
            onFinish ={(values) => {
                userUpdateRequest({
                    variables: {
                        input: { ...values }
                    }
                }).catch((error) => {
                    errorNotification(error);
                });
            }}
        >
            <Form.Item
                name="first_name"
                {...formItemLayout}
                initialValue={ me.first_name }
                rules={[
                    {
                        required: true,
                        message: 'Please input your First name'
                    }
                ]}
            >
                <FloatLabel label="Vorname*">
                    <Input name="first_name" />
                </FloatLabel>
            </Form.Item>
            <Form.Item
                name="last_name"
                initialValue={ me.last_name }
                {...formItemLayout}
                rules={[
                    {
                        required: true,
                        message: 'Please input your Last name'
                    }
                ]}
            >
                <FloatLabel label="Nachname*">
                    <Input name="last_name" />
                </FloatLabel>
            </Form.Item>
            <Form.Item
                name="phone"
                initialValue={ me.phone }
                {...formItemLayout}
            >
                <FloatLabel label="Telefonnummer">
                    <Input name="phone_number" />
                </FloatLabel>
            </Form.Item>
            <Form.Item>
                <p className="text-type3-light">
                    Du kannst deine E-Mail-Adresse nicht bearbeiten.
                    Du kannst aber deinen Account löschen um dich mit einer anderen E-Mail-Adresse anzumelden.
                </p>
            </Form.Item>
            <div className="form-actions">
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={ loading }
                    disabled={ loading }
                >
                    ABSENDEN
                </Button>
            </div>
        </Form>
    );
}

export default UserProfileForm;