import React, { useEffect } from "react";
import { Button } from "antd";

import { CompanyMenu } from "components/menus";
import { CompanyRoutes, NoCompanyRoutes } from "components/routes";
import { useMe, useVarParam, useLogout } from "components/use-hooks";
import { ContainerWrap, TwoColWrap, WrapColumn } from "components/wraps";
import { checkUser } from "utils";



const CompanyPage = () => {

    const appParams = useVarParam(),
          { logout } = useLogout(),
          { me } = useMe();


    useEffect(() => {

        if(!checkUser.hasActiveCompany(me.company)){
            appParams.set({
                rightHeaderControl:
                    <Button
                        onClick={ () => logout({ redirectRoute: "/" }) }
                        type="link"
                    >
                        Logout
                    </Button>
            });
        }

        return () => {
            appParams.set({
                rightHeaderControl: false
            });
        }
    });

    if(!checkUser.hasActiveCompany(me.company)){
        return(
            <ContainerWrap className="company-page-external">
                <NoCompanyRoutes me={ me } />
            </ContainerWrap>
        );
    }

    return(
        <ContainerWrap className="page company-page">
            <TwoColWrap>
                <WrapColumn position="left">
                    <CompanyMenu isAdmin={ checkUser.isAdmin(me.role) } />
                </WrapColumn>
                <WrapColumn position="right">
                    <CompanyRoutes me={ me } />
                </WrapColumn>
            </TwoColWrap>
        </ContainerWrap>
    );

};

export default CompanyPage;