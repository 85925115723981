import Router from "./router";
import AppRoutes from "./app-routes";
import GuestRoutes from "./guest-routes";
import { NoCompanyRoutes, CompanyRoutes } from "./company";
import LocationRoutes from "./location-routes";


export {
    AppRoutes,
    GuestRoutes,
    CompanyRoutes,
    NoCompanyRoutes,
    LocationRoutes,
}


export default Router;