import React from "react";
import { Switch, Route } from 'react-router-dom';

import { Pages } from "components/pages";
import { useMe } from "components/use-hooks";
import { checkUser } from "utils";


const { hasActiveCompany } = checkUser;

const AppRoutes = () => {

    const { me } = useMe();

    return(
        <Switch>
            <Route path={ !hasActiveCompany(me.company) ? "*" : "/company/" }>
                <Pages.CompanyPages.Page />
            </Route>
            <Route path="/" exact>
                <Pages.HomeAppPage/>
            </Route>
            <Route path="/locations/" exact>
                <Pages.LocationPages.All />
            </Route>
            <Route path="/locations/:locationId/">
                { ({ match }) => <Pages.LocationPages.Single locationId={ match.params.locationId } />  }
            </Route>
            <Route path="*" >
                <Pages.Page404/>
            </Route>
        </Switch>
    );
};

export default AppRoutes;