import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Select, Form, Tag } from "antd";

import { GET_COMPANIES } from "graphql/query/company-gql";
import { COMPANY_ASSIGN } from "graphql/mutation/company-gql";

import { FloatLabel } from "components/service";
import { errorNotification } from "components/request-result";

import "./company-selector.scss";


const { Option } = Select;

const CompanySelector = ({ me }) => {

    const [ selectedCompany, setSelectedCompany ] = useState();
    const history = useHistory();

    const { data : { companies } = { }, loading } = useQuery( GET_COMPANIES );

    const [ assignRequest, { loading : assignLoading }] = useMutation( COMPANY_ASSIGN,
        {
            update(cache, { data }) {

                cache.modify({
                    id: cache.identify(me),
                    fields: {
                        company(){
                            return { ...data.companySelect } ;
                        },
                        company_id(){
                            return data.companySelect.id;
                        }
                    },
                });

                history.replace("/")

                //setTimeout(() => history.replace("/"), 10);
            }
        }
    );


    return(
        <div className="company-selector">
            <Form>
                <Form.Item
                    name="company"

                >
                    <FloatLabel label="Firmenliste*"
                                onChange={ (value) => setSelectedCompany( value ) }
                    >
                        <Select loading={ loading }>
                            { companies && companies.data.map(
                                ( company ) => {
                                    return(
                                        <Option
                                            key={company.id}
                                            value={ company.id }
                                            disabled={ company.status !== "active" }
                                        >
                                            <span>{ company.title ?? `Company #${company.id}` }</span>
                                            <Tag color={ company.status === "active" ? "orange" : "" }>{ company.status }</Tag>
                                        </Option>
                                    )
                                }
                            )}
                        </Select>
                    </FloatLabel>
                </Form.Item>
            </Form>
            <div className="action">
                <Button
                    type="primary"
                    loading={ assignLoading }
                    disabled={ !selectedCompany }
                    onClick={ () => {
                        assignRequest({
                            variables: { id: selectedCompany }
                        }).catch((error) => {
                            errorNotification(error);
                        });

                    }}
                >
                    Auswählen
                </Button>
            </div>
        </div>
    )
}

export default CompanySelector;