import React from "react";
import { NavLink } from "react-router-dom";


const CompanyMenu = ({ isAdmin = false }) => {


    return(
        <nav className="company-menu-wrap">
            <ul className="menu company-menu">
                { isAdmin &&
                    <li>
                        <NavLink to="/company/select" exact>Select company</NavLink>
                    </li>
                }
                <li>
                    <NavLink to="/company/" exact>Company profile</NavLink>
                </li>
                <li>
                    <NavLink to="/company/users" exact>Company employees</NavLink>
                </li>
                <li>
                    <NavLink to="/company/my-profile/" exact>My profile</NavLink>
                </li>
                <li>
                    <NavLink to="/company/change-password/" exact>Change password</NavLink>
                </li>

            </ul>
        </nav>
    );
};

export default CompanyMenu;
