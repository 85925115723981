import React from "react";

import Icons from "components/icons";


const LocationFileWrap = ({ filepath }) => {

    const filename = filepath.replace(/^.*[\\/]/, '');

    return(
        <a
            href={filepath}
            target={"_blank"}
            rel="noreferrer"
            className="location-link-file-wrap"
        >
            <Icons.File />{ filename }
        </a>
    );
}

export default LocationFileWrap;