import React from "react";
import { Button } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import { Forms } from "components/location";
import { ModalStandard } from "components/service";



const LocationVisitsNotice = ({ visit = {} }) => {

    const {
        covid_id,
        covid = {}
    } = visit;

    let buttonClass = "create";

    if(covid_id && covid.status === "new"){
        buttonClass = "danger";
    } else if(covid_id && covid.status === "approved"){
        buttonClass = "approved";
    }

    return(
        <ModalStandard
            modalButton={
                <Button className={ `icon rounded-primary ${ buttonClass }` }>
                    <WarningOutlined />
                </Button>
            }
        >
            <Forms.Notice
                visit={ visit }
                covid={ covid_id ? covid : undefined }
                create={ !covid_id }
            />
        </ModalStandard>
    );
}

export default LocationVisitsNotice;