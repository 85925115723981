import React from "react";

import { CompanyProfileForm } from "components/company";


const CompanyEditSubPage = ({ me }) => {

    return(
        <div className="company-edit-sub-page">
            <h1>Company profile</h1>
            <CompanyProfileForm me={ me }/>
        </div>
    );
}

export default CompanyEditSubPage;