import React from "react";
import { useRouteMatch } from "react-router-dom";

import { LocationVisits } from "components/location";


const LocationVisitsSubPage = ({ location }) => {

    const basePath = `/locations/${ location.id }/visits/`,
          match = useRouteMatch( `${ basePath }:currentPage` ),
          currentPage = match ? parseInt( match.params.currentPage ) : 1;

    return(
        <div className="location-visits-sub-page">
            <h1>Location Visits</h1>
            <LocationVisits
                locationId={ location.id }
                currentPage={ currentPage }
                basePath={ basePath }
                perPage={ 15 }
            />
        </div>
    );
}

export default LocationVisitsSubPage;