import React from "react";
import { Button } from "antd";

import Forms from "../forms";
import { ModalStandard } from "components/service";


const LocationCreate = () => {

    return (

        <ModalStandard
            modalButton={
                <Button type="primary">
                    Create Location
                </Button>
            }
            extraClass={ "location-create-modal" }
        >
            <h1>Create new location</h1>
            <Forms.Create />
        </ModalStandard>

    );
}

export default LocationCreate;