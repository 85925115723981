import CPGeneralInfo from "./cp-general-info-form";
import CPContactInfo from "./cp-contact-info-form";
import CPAddressInfo from "./cp-address-info-form";

const CPForms = {
    GeneralInfo : CPGeneralInfo,
    ContactInfo : CPContactInfo,
    AddressInfo : CPAddressInfo
}

export default CPForms;