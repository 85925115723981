import React from "react";
import { useRouteMatch } from "react-router-dom";

import { Forms, AuthConfirmAccount } from "components/auth";
import { ContainerWrap } from "components/wraps";


const RegistrationPage = () => {

    const match = useRouteMatch( "/registration/:token");

    return(
        <ContainerWrap className="page">
            <div className="block">
                { match ?
                    <AuthConfirmAccount token={ match.params.token } />:
                    <Forms.Registration />
                }
            </div>
        </ContainerWrap>
    );
};

export default RegistrationPage;