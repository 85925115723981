import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { USER_LOGOUT } from "graphql/mutation/user-gql";
import { IS_LOGGED_IN } from  "graphql/query/local-store-gql";

import { errorNotification } from "../request-result";
import useVarParam from "./use-var-param";


const useLogout = () => {

    const history = useHistory(),
          location = useLocation(),
          [userLogout, {client}] = useMutation( USER_LOGOUT );

    const varParam = useVarParam();

    const clearStore = ({ route = false }) => {

        localStorage.clear();
        //client.resetStore().catch((error) => { //console.log(error)});

        client.cache.reset().then(
            () => {
                if(route !== undefined && route){
                    history.push(route ?? "/", { path: location.pathname });
                    varParam.reset();
                }
            }
        );

    };

    const logout = ({ redirectRoute = "/"}) => {
        userLogout({
            update(cache) {

                cache.writeQuery({
                    query: IS_LOGGED_IN,
                    data: {
                        isLoggedIn: false
                    },
                });


                setTimeout( () => {
                    clearStore({ route: redirectRoute })
                }, 100 );
            }
        }).catch((error) => {
            errorNotification(error);
        });
    };

    return {
        logout, clearStore
    };

};

export default useLogout;