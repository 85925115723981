import LUGeneralInfo from "./lu-general-info-form";
import LUContactInfo from "./lu-contact-info-form";
import LUAddressInfo from "./lu-address-info-form";

const LUForms = {
    GeneralInfo : LUGeneralInfo,
    ContactInfo : LUContactInfo,
    AddressInfo : LUAddressInfo
}

export default LUForms;