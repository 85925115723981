import { useMutation } from "@apollo/client";

import { VISIT_NOTICE_FORM_CREATE } from "graphql/mutation/visits-gql";

import { successNotification, errorNotification } from "components/request-result";


const useLocationNoticeCreate = (visit) => {

    const [formCreate, { loading }] = useMutation( VISIT_NOTICE_FORM_CREATE,
        {
            update(cache, { data }) {

                const {
                    noticeFormCreate : {
                        label,
                        message,
                        noticeForm
                    }
                } = data;

                cache.modify({
                    id: cache.identify( visit ),
                    fields: {
                        covid_id(){
                            return noticeForm.id;
                        },
                        covid(){
                            return { __ref: `${ noticeForm.__typename }:${ noticeForm.id }`};
                        }
                    },
                });

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return [ formCreate, loading ];
}


export default useLocationNoticeCreate;