import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_VISITS } from "graphql/query/visits-gql";

import LocationVisitsFilter from "./location-visits-filter";
import LocationVisitsTable from "./location-visits-table";

import "./location-visits.scss";


const LocationVisits = ({ locationId, currentPage, basePath, perPage }) => {

    const history = useHistory();

    const defaultOrder = { column: "CREATED_AT", order: "DESC" };

    const [ searchText, setSearchText ] = useState ( "" ),
          [ orderBy, setOrderBy ] = useState ( [ defaultOrder ] ),
          [ filters, setFilters ] = useState(),
          [ total, setTotal ] = useState();

    const { data : { visits = {} } = {} , loading } = useQuery( GET_VISITS, {
        variables: {
            locationId,
            searchText,
            page : currentPage,
            perPage,
            orderBy,
            where: {
                ...filters
            }
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-only"
    });

    const { paginatorInfo = {} } = visits;

    useEffect( () => {

        if( (paginatorInfo && !total) ||  (paginatorInfo.total >= 0 && paginatorInfo.total !== total) ){
            setTotal( paginatorInfo.total );
            history.replace( basePath );
        }

    }, [ paginatorInfo, total, history, basePath ] );


    return(
        <>
            <LocationVisitsFilter
                setFilters={ setFilters }
                setSearchText={ setSearchText }
                searchText={ searchText }
                locationId={ locationId }
            />
            <LocationVisitsTable
                data={ visits.data }
                loading={ loading }
                setOrderBy={ setOrderBy }
                defaultOrder={ defaultOrder }
                pagination={{
                    pageSize: perPage,
                    total,
                    hideOnSinglePage: (!total) || (total - perPage < 0),
                    current: currentPage,
                    onChange: ( page ) => {
                        history.push( page === 1 ? basePath : `${ basePath }${ page }` );
                    }
                }}
            />
        </>
    );
};

export default LocationVisits;