import React from "react";
import { Switch, Route } from 'react-router-dom';

import { GuestPages } from "components/pages";


const GuestRoutes = () => {

    return(
        <Switch>
            <Route path="/registration/" >
                <GuestPages.RegistrationPage />
            </Route>
            <Route path="/forgot-password/">
                <GuestPages.ForgotPasswordPage />
            </Route>
            <Route>
                <GuestPages.LoginPage />
            </Route>
        </Switch>
    );
};

export default GuestRoutes;