import React from "react";

import { CompanyProfileForm, CompanyCreate } from "components/company";



const CompanyCreateSubPage = ({ me }) => {

    return(
        <div className="company-create-sub-page">
            { me.company ?
                <CompanyProfileForm
                    me={ me }
                    type="steps"
                /> :
                <CompanyCreate me={ me } />
            }
        </div>

    );
};

export default CompanyCreateSubPage;