const recursiveWhere = ( arr, condition ) => {

    const [ whereItem, ...newArr ] = arr;

    if(newArr.length){
        return {
            ...whereItem,
            [ condition ] : [{ ...recursiveWhere( newArr, condition)}]
        };
    }

    return whereItem;

};


export const whereConditionBuilder = ( filterObj, condition = "AND" ) => {

    let whereArr = [];

    for( let [ key, item ] of Object.entries( filterObj )){

        if( typeof item === "object"){
            if( Object.keys( item ).length ){

                whereArr.push( { ...item} );

            }
        } else {
            if( item ){
                whereArr.push(
                    {
                        column: key.toUpperCase(),
                        operator: "IN",
                        value: item
                    }
                )
            }
        }

    }

    return recursiveWhere( whereArr.reverse(), condition );

};
