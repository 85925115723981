import React from "react";
import { useMutation } from "@apollo/client";
import { Form, Input, Select } from "antd";

import { LOCATION_META_UPLOAD } from "graphql/mutation/location-gql";

import { AvatarUpload } from "components/avatar";
import { FloatLabel } from "components/service";
import { successNotification } from "components/request-result";
import { metaFields } from "utils";


const { TextArea } = Input;
const { Option } = Select;

const LUGeneralInfo = ({ form, formLayout, location, ...props }) => {

    const { normalize, getValue } = metaFields;
    const meta = normalize(location.meta_fields);

    const [metaUpload, { loading }] = useMutation(LOCATION_META_UPLOAD,
        {
            update(cache, { data }) {

                const {
                    locationMetaUpload : {
                        label,
                        message,
                        location : {
                            logo
                        }
                    }
                } = data;

                form.setFieldsValue({ "logo-image-unset" : logo });

                successNotification({
                    title: label,
                    description: message
                });
            }
        });

    return (
        <Form
            name="location-general-info"
            form={ form }
            {...formLayout}
            onFinish={
                ( values ) => {
                    if( props.submitAction) {
                        props.submitAction( values );
                    }
                }
            }
        >
            <Form.Item
                label={ <p className="text-type2">Logo</p>}
                name="logo-image-unset"
                initialValue={ location.logo }
            >
                <AvatarUpload
                    uploadMutation={ metaUpload }
                    variables={{
                        input: {
                            meta_key : "logo",
                            location_id : location.id
                        }
                    }}
                    image={ location.logo }
                    loading={ loading }
                    uploadButtonText={ "Upload Logo" }
                />
            </Form.Item>
            <Form.Item
                name="location_name-field"
                initialValue={ location.title }
                rules={ [
                    { required: true, message: 'Please input Location name' }
                ]}
            >
                <FloatLabel label="Location name*">
                    <Input name="location_name" />
                </FloatLabel>
            </Form.Item>
            <div className="field-group">
                <Form.Item
                    name="type-field-unset"
                    initialValue={ location.type }
                    rules={ [
                        { required: true, message: 'Please input Company name' }
                    ]}
                >
                    <FloatLabel label="Location type*" disabled>
                        <Select name="location_type">
                            <Option value="restaurant">Restaurant</Option>
                            <Option value="event">Event</Option>
                            <Option value="hotel">Stay</Option>
                        </Select>
                    </FloatLabel>
                </Form.Item>
                <Form.Item
                    name="status-field"
                    initialValue={ location.status }
                    rules={ [
                        { required: true, message: 'Please select location status' }
                    ]}
                >
                    <FloatLabel label="Location status*">
                        <Select name="location_status">
                            <Option value="draft">Draft</Option>
                            <Option value="active">Active</Option>
                            <Option value="closed">Closed</Option>
                        </Select>
                    </FloatLabel>
                </Form.Item>
            </div>
            <Form.Item
                shouldUpdate
                name="location_description-string--general"
                initialValue={ getValue( meta, "location_description" ) }
            >
                <FloatLabel label="Short text about location">
                    <TextArea
                        rows={6}
                        maxLength={ 600 }
                        showCount={
                            { formatter: ({ count, maxLength }) => maxLength - count}
                        }
                    />
                </FloatLabel>
            </Form.Item>
        </Form>
    );
};

export default LUGeneralInfo;