import React from "react";
import { Button } from "antd";

import CPForms from "./form-part";


const CompanyProfileFormSet = ({ formParams, formSubmit, loading }) => {

    return(
        <div className="form-set">
            <div className="form-wrap">
                <h4>General info</h4>
                <CPForms.GeneralInfo { ...formParams } />
            </div>
            <div className="form-wrap">
                <h4>Contact info</h4>
                <CPForms.ContactInfo { ...formParams } />
            </div>
            <div className="form-wrap">
                <h4>Address info</h4>
                <CPForms.AddressInfo
                    submitAction={ ( values ) => formSubmit( values )}
                    { ...formParams }
                />
            </div>

            <div className="actions">
                <Button
                    type="primary"
                    loading={ loading }
                    onClick={() => formParams.form.submit()}
                >
                    Save
                </Button>
            </div>
        </div>
    );
}

export default CompanyProfileFormSet;