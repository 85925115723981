import React from "react";
import { useMutation } from "@apollo/client";
import { Form } from "antd";

import { COMPANY_UPDATE } from "graphql/mutation/company-gql";

import CompanyProfileStepForm from "./company-profile-step-form";
import CompanyProfileFormSet from "./company-profile-form-set";
import { errorNotification, successNotification } from "components/request-result";
import { metaFields } from "utils";


const formsSubmit = ({ values, updateCompanyProfile, company }) => {

    let { metaValues, fieldValues } = metaFields.parseForm(values);

    metaValues = metaFields.filterEmpty(metaValues, company.meta_fields);

    updateCompanyProfile({
        variables: {
            id : parseInt(company.id),
            companyInput: {
                title: fieldValues.company_name,
                email: fieldValues.email,
                business_ID: fieldValues.business_id,
                status: company.status === "draft" ? "active" : company.status
            },
            metaInput: [ ...metaValues ]
        }
    })

};

const CompanyProfileForm = ({ me, type = "set" }) => {

    const [ form ] = Form.useForm();

    const [ updateCompanyProfile, { loading }] = useMutation( COMPANY_UPDATE,
        {
            update(cache, { data }) {

                const { label, message } = data.companyUpdate;

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    const company = {
        meta_fields : {},
        ...me.company
    }

    const formSubmit = ( values ) => {
        formsSubmit( {
            values,
            updateCompanyProfile,
            company
        })
    }

    const formParams = {
        form,
        company,
        formLayout : {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        }
    };

    if( type === "steps" ){
        return (
            <CompanyProfileStepForm
                formParams={ formParams }
                formSubmit={ formSubmit }
                loading={ loading }
            />
        );
    }

    return (
        <CompanyProfileFormSet
            formParams={ formParams }
            formSubmit={ formSubmit }
            loading={ loading }
        />
    );
}

export default CompanyProfileForm;