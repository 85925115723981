import { gql } from '@apollo/client';


export const COMPANY_ASSIGN = gql`
    mutation CompanySelect($id: ID!) {
        companySelect(id: $id){
            id
            title
            status
            logo
            business_ID
            email
            meta_fields{
                id
                meta_group
                meta_key
                meta_type
                meta_value
            }
            created_at
            updated_at
            delete_at
        }
    }
`;

export const COMPANY_CREATE = gql`
    mutation CompanyCreate{
        companyCreate{
            label
            message
            company{
                id
                status
            }
        }
    }
`;

export const COMPANY_UPDATE = gql`
    mutation CompanyUpdate(
        $id : ID!
        $companyInput : CompanyUpdateInput
        $metaInput : [CompanyMetaInput]
    ){
        companyUpdate(
            id : $id
            input : $companyInput
        ){
            label
            message
        }
        companyMetaSet(
            company_id : $id
            input : $metaInput
        ){
            label
            message
            company{
                id
                title
                status
                logo
                business_ID
                email
                meta_fields{
                    id
                    meta_group
                    meta_key
                    meta_type
                    meta_value
                }
                created_at
                updated_at
                delete_at
            }
        }
    }
`;

export const COMPANY_META_SET = gql`
    mutation CompanyMetaSet(
        $company_id : ID!
        $input : [CompanyMetaInput]
    ){
        companyMetaSet(
            company_id : $company_id
            input : $input
        ){
            label
            message
            company{
                id
                title
                status
                logo
                business_ID
                meta_fields{
                    id
                    meta_group
                    meta_key
                    meta_type
                    meta_value
                }
                created_at
                updated_at
                delete_at
            }
        }
    }
`;