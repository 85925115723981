import React from "react";

import { Forms } from "components/location";


const LocationMenuSubPage = ({ location }) => {

    return(
        <div className="location-menu-sub-page">
            <h1>Location Menus</h1>
            <Forms.Menus location={ location } />
        </div>
    );
}

export default LocationMenuSubPage;