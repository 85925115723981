import React, { useState, useEffect } from "react";
import { Input } from "antd";

import { FloatLabel } from "components/service";


const LocationVisitsFilterSearch = ({ setSearchText }) => {

    const [ inputText, setInputText ] = useState("");

    useEffect( () => {
        if(inputText.length > 2 || inputText.length === 0){
            setSearchText(inputText)
        }
    }, [inputText, setSearchText]);

    return(
        <FloatLabel
            label="Search text"
            float={ !!inputText.length }
        >
            <Input
                onChange={
                    ( e ) => {
                        setInputText(e.target.value);
                    }
                }
            />
        </FloatLabel>
    )
}

export default LocationVisitsFilterSearch;