import { gql } from '@apollo/client';


export const GET_ME = gql`
    query GetMe {
        me{
            id
            first_name
            last_name
            email
            phone
            language_slug
            status
            role
            avatar
            company_id
            company{
                id
                title
                status
                logo
                business_ID
                email
                meta_fields{
                    id
                    meta_group
                    meta_key
                    meta_type
                    meta_value
                }
                created_at
                updated_at
                delete_at
            }
            updated_at
            created_at
        }
    }
`;