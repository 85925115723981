import { useMutation } from "@apollo/client";

import { VISIT_NOTICE_FORM_UPDATE  } from "graphql/mutation/visits-gql";

import { successNotification, errorNotification } from "components/request-result";


const useLocationNoticeUpdate = ( visit ) => {

    const [ formUpdate, { loading }] = useMutation( VISIT_NOTICE_FORM_UPDATE,
        {
            update(cache, { data }) {

                const {
                    noticeFormUpdate : {
                        label,
                        message,
                        noticeForm
                    }
                } = data;

                if( noticeForm.status === 'declined' ){

                    cache.modify({
                        id: cache.identify( visit ),
                        fields: {
                            covid_id(){
                                return null;
                            },
                            covid(){
                                return null;
                            }
                        },
                    });

                    cache.evict( { id: cache.identify( noticeForm )});
                    cache.gc();

                }

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }

    );
    return [ formUpdate, loading ];
}


export default useLocationNoticeUpdate;