import React from "react";

import { Forms } from "components/auth";
import { useRouteMatch } from "react-router-dom";
import { ContainerWrap } from "components/wraps";


const ForgotPasswordPage = () => {

    const match = useRouteMatch( "/forgot-password/:token");

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    return(
        <ContainerWrap className="page">
            <div className="block">
                { match ?
                    <Forms.ChangeForgotPassword forgotToken={ match.params.token } />:
                    <Forms.ForgotPassword
                        formItemLayout={ formItemLayout }
                    />
                }
            </div>
        </ContainerWrap>
    );
};

export default ForgotPasswordPage;