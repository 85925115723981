import React from "react";


import {Forms} from "components/user";


const CompanyChangePasswordSubPage = () => {

    return(
        <div className="company-change-password-sub-page">
            <h1>Passwort ändern</h1>
            <Forms.ChangePassword />
        </div>
    );
}

export default CompanyChangePasswordSubPage;