const normalize = ( data, loading, onlyHeader = false) => {

    const dataSource = [],
          columns = [];

    data.forEach( (row) =>  {

        let item = {};

        for (let [key, cell] of Object.entries(row)) {

            if(typeof cell === 'object') {

                item[key] = !loading ? cell.value : cell.empty;

                if( Object.keys(row).length - 1 !== columns.length ) {
                    columns.push({
                        title: <p>{ cell.title }</p>,
                        dataIndex: key,
                        columnIndex: cell.columnIndex,
                        key: key,
                        sorter: cell.sorter,
                        width: cell.width,
                        className: cell.className,
                        ellipsis: true
                    });
                }
            } else {
                item[key] = cell;
            }
        }

        if(onlyHeader){
            return {
                dataSource,
                columns
            }
        }

        dataSource.push(item);
    });

    return {
        dataSource,
        columns
    }
}

const tableRowsCount = ({ total, pageSize, current }) => {

    if( total ){
        const count = (total - (current-1) * pageSize);

        return count < pageSize ? count : pageSize
    }

    return pageSize;
}

const tableHelper = {
    normalize,
    tableRowsCount
}

export default tableHelper;