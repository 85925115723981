import React from "react";
import { Form, Input } from "antd";

import { FloatLabel } from "components/service";
import { metaFields } from "utils";


const CPAddressInfo = ({ form, formLayout, company, title,...props }) => {

    const { normalize, getValue } = metaFields;
    const meta = normalize(company.meta_fields);

    return (
        <>
            { title }
            <Form
                name="company-address-info"
                form={ form }
                {...formLayout}
                onFinish={
                    ( values ) => {
                        if( props.submitAction) {
                            props.submitAction( values );
                        }
                    }
                }
            >
                <div className="field-group">
                    <Form.Item
                        name="company_country-string--address"
                        initialValue={ getValue( meta, "company_country") }
                        rules={[
                            {
                                required: true, message: 'Field required'
                            }
                        ]}
                    >
                        <FloatLabel label="Land*">
                            <Input name="company_country" />
                        </FloatLabel>
                    </Form.Item>
                    <Form.Item
                        name="company_city-string--contacts"
                        initialValue={ getValue( meta, "company_city") }
                        rules={[
                            {
                                required: true, message: 'Field required'
                            }
                        ]}
                    >
                        <FloatLabel label="Stadt*">
                            <Input name="company_city" />
                        </FloatLabel>
                    </Form.Item>
                </div>
                <div className="field-group">
                    <Form.Item
                        name="company_address-string--address"
                        initialValue={ getValue( meta, "company_address") }
                        rules={[
                            {
                                required: true, message: 'Field required'
                            }
                        ]}
                    >
                        <FloatLabel label="Die Adresse*">
                            <Input name="company_address" />
                        </FloatLabel>
                    </Form.Item>
                    <Form.Item
                        name="company_zip-string--contacts"
                        initialValue={ getValue( meta, "company_zip") }
                        rules={[
                            {
                                required: true, message: 'Field required'
                            }
                        ]}
                    >
                        <FloatLabel label="ZIP*">
                            <Input name="company_zip" />
                        </FloatLabel>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
};

export default CPAddressInfo;