import React from "react";

import { CompanySelector } from "components/company";


const CompanySelectSubPage = ( { me, internal } ) => {

    return(
        <div className={ `company-select-sub-page ${internal ? "internal" : "" }`}>
            <h1>Unternehmen wählen</h1>
            <CompanySelector me={ me } />
        </div>
    );
}

export default CompanySelectSubPage;