import React from "react";
import { useQuery } from "@apollo/client";

import { GET_LOCATIONS } from "graphql/query/location-gql";

import LocationsGridItem from "./locations-grid-item";
import { useMe } from "components/use-hooks";
import { Loader } from "components/request-result";

import "./location-grid.scss";


const LocationsGrid = (  ) => {

    const { me } = useMe();

    const { data : { locations } = { }, loading } = useQuery( GET_LOCATIONS, {
        variables: {
            companyId : me.company.id,
            first: 50
        },
        fetchPolicy: "cache-and-network"
    });

    if(loading){
        return <Loader type="block" />;
    }

    return(
        <div className="locations-grid grid-three-col">
            { !loading && locations && locations.data.map(
                (location) => <LocationsGridItem location={ location } key={ location.id } />
            )}
            { !loading && !locations && <div>No locations</div>}
        </div>
    );

};

export default LocationsGrid;