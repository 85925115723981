import CompanySelectSubPage from "./company-select-sub-page";
import CompanyCreateSubPage from "./company-create-sub-page";
import CompanyEditSubPage from "./company-edit-sub-page";
import CompanyMyProfileSubPage from "./company-my-profile-sub-page";
import CompanyChangePasswordSubPage from "./company-change-password-sub-page";


const companySubPage = {
    Select         : CompanySelectSubPage,
    Create         : CompanyCreateSubPage,
    Edit           : CompanyEditSubPage,
    MyProfile      : CompanyMyProfileSubPage,
    ChangePassword : CompanyChangePasswordSubPage,
}


export default companySubPage;