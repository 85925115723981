import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { LOCATION_META_UPLOAD } from "graphql/mutation/location-gql";

import { UploadFile } from "components/service";
import { Loader, successNotification } from "components/request-result";
import { ImageLoader } from "components/service";

import "./location-banner.scss";


const LocationBanner = ({
        image,
        classname = "",
        locationId,
        imageKey,
        noImage = "Please upload location banner"
    }) => {

    const [ locationUploadBanner, { loading } ] = useMutation( LOCATION_META_UPLOAD,
        {
            update(cache, { data }) {

                const { label, message } = data.locationMetaUpload;

                successNotification({
                    title: label,
                    description: message
                });

            }
        });

    const classNames = [ classname, image ? "" : "empty" ].filter(n => n).join(" ");

    return(
        <div className={ `location-banner-image ${ classNames }`}>
            { image ?
                <ImageLoader
                    wrapClass={ "image-wrap" }
                    altText={ "location_banner" }
                    image={ image }
                /> :
                <>
                    { noImage &&
                        <div className="no-image-wrap">{ noImage }</div>
                    }
                </>

            }
            { locationId && imageKey &&
                <UploadFile
                    extraClass="location-banner-upload"
                    loading={loading}
                    uploadMutation={locationUploadBanner}
                    variables={{
                        input: {
                            location_id: locationId,
                            meta_key: imageKey
                        }
                    }}
                >
                    <Button type="primary" disabled={loading}>
                        {loading && <Loader/>}
                        <span>{image ? "Change" : "Add"} banner</span>
                    </Button>
                </UploadFile>
            }
        </div>
    );
};


export default LocationBanner;