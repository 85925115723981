import { gql } from '@apollo/client';


export const GET_COMPANIES = gql`
    query GetCompanies(
        $first: Int 
        $page: Int
    ){
        companies(
            first: $first
            page: $page            
        ){
            paginatorInfo {
                currentPage
                hasMorePages
                total
            }
            data{
                id
                title
                status
            }
        }
    }
`;

export const GET_COMPANY = gql`
    query GetCompany( $id : ID! ){
        company( id : $id ){
            id
            title
            status
            logo
            business_ID
            meta_fields{
                id
                meta_group
                meta_key
                meta_type
                meta_value
            }
            created_at
            updated_at
            delete_at
        }
    }
`;