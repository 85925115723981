import React from "react";
import { Form, Input } from "antd";

import { FloatLabel } from "components/service";
import { useMe } from "components/use-hooks";
import { metaFields } from "utils";


const CPContactInfo = ({ form, formLayout, company, title, ...props }) => {

    const { me } = useMe();

    const { normalize, getValue } = metaFields;
    const meta = normalize(company.meta_fields);

    return (
        <>
            { title }
            <Form
                name="company-contact-info"
                form={form}
                {...formLayout}
                onFinish={
                    ( values ) => {
                        if( props.submitAction) {
                            props.submitAction( values );
                        }

                    }
                }
            >
                <div className="field-group">
                    <Form.Item
                        name="first_name-string--contacts"
                        initialValue={ getValue( meta, "first_name", me.first_name) }
                        rules={[
                            {
                                required: true,
                                message: 'Please input your First name'
                            }
                        ]}
                    >
                        <FloatLabel label="Vorname*">
                            <Input name="first_name" />
                        </FloatLabel>
                    </Form.Item>
                    <Form.Item
                        name="last_name-string--contacts"
                        initialValue={ getValue( meta, "last_name", me.last_name) }
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Last name'
                            }
                        ]}
                    >
                        <FloatLabel label="Nachname*">
                            <Input name="last_name" />
                        </FloatLabel>
                    </Form.Item>
                </div>
                <div className="field-group">
                    <Form.Item
                        name="email-field"
                        initialValue={ getValue(meta, "company_email", me.email) }
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: 'The input is not valid E-mail',
                            }
                        ]}
                    >
                        <FloatLabel label="E-Mail*">
                            <Input name="company_email" />
                        </FloatLabel>
                    </Form.Item>
                    <Form.Item
                        name="company_site_url-string--contacts"
                        initialValue={ getValue( meta, "company_site_url") }
                    >
                        <FloatLabel label="Website">
                            <Input name="company_website" />
                        </FloatLabel>
                    </Form.Item>
                </div>
                <div className="field-group">
                    <Form.Item
                        name="company_phone-string--contacts"
                        initialValue={ getValue( meta, "company_phone", me.phone) }
                        rules={[
                            {
                                required: true,
                                message: 'Please put contact phone',
                            }
                        ]}
                    >
                        <FloatLabel label="Phone*">
                            <Input name="company_phone" />
                        </FloatLabel>
                    </Form.Item>
                    <Form.Item
                        name="company_fax-string--contacts"
                        initialValue={ getValue( meta,  "company_fax" ) }
                    >
                        <FloatLabel label="Fax">
                            <Input name="company_fax" />
                        </FloatLabel>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
};

export default CPContactInfo;