import React from "react";
import { Form, Select, Input, Button } from "antd";

import useLocationNoticeCreate from "./hooks/use-location-notice-create";
import useLocationNoticeUpdate from "./hooks/use-location-notice-update";

import { errorNotification } from "components/request-result";
import {FloatLabel} from "../../../service";


const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const LocationNoticeForm = ({ visit = {}, covid = {}, create = true }) => {

    const [ form ] = Form.useForm();

    const [ noticeCreate , createLoading ] = useLocationNoticeCreate(visit),
          [ noticeUpdate , updateLoading ] = useLocationNoticeUpdate(visit);

    const loading = !create ? updateLoading : createLoading;

    const {
        message = "",
        moderator_notice = "",
        status = "new"
    } = covid;

    return (
        <Form
            key="location-notice-form"
            form={ form }
            {...layout}
            onFinish={(values) => {

                if( !create ){
                    noticeUpdate({
                        variables: {
                            id: visit.covid_id,
                            input: {
                                visit_id: visit.id,
                                ...values
                            }
                        }
                    }).catch((error) => {
                        errorNotification(error);
                    });
                } else {
                    noticeCreate({
                        variables: {
                            input: {
                                visit_id: visit.id,
                                ...values
                            }
                        }
                    }).catch((error) => {
                        errorNotification(error);
                    });
                }

            }}
        >
            <Form.Item
                initialValue={ visit.email }
                name={ 'email' }
            >
                <FloatLabel label="Visitor E-Mail">
                    <Input disabled name="emil" />
                </FloatLabel>
            </Form.Item>
            <Form.Item
                initialValue={ message }
                name={'message'}
                rules={[
                    { message: 'Please input message' }
                ]}
            >
                <FloatLabel label="Message">
                    <Input.TextArea
                        rows={3}
                        maxLength={ 2500 }
                        name="message"
                        disabled={ !create }
                    />
                </FloatLabel>
            </Form.Item>
            <Form.Item
                initialValue={ moderator_notice }
                name={'moderator_notice'}
                rules={[
                    { message: 'Please input Title' }
                ]}
            >
                <FloatLabel label="Moderator Notice">
                    <Input.TextArea
                        rows={ 3 }
                        maxLength={ 2500 }
                        name="moderator_notice"
                    />
                </FloatLabel>
            </Form.Item>
            <Form.Item
                initialValue={ status }
                name={'status'}
                rules={[
                    { required: true, message: 'Please select status' }
                ]}
            >
                <FloatLabel label="Status">
                    <Select key="notice-status">
                        <Option disabled={ covid.status === "approved" } value="new">New</Option>
                        <Option value="approved">Approved</Option>
                        <Option disabled={ create } value="declined">Declined</Option>
                    </Select>
                </FloatLabel>
            </Form.Item>

            <div className="form-actions">
                <Button
                    loading={ loading }
                    disabled={ loading }
                    type="primary"
                    htmlType="submit"
                >
                    { !create  ? "Update" : "Create" }
                </Button>
            </div>
        </Form>
    )
}

export default LocationNoticeForm;