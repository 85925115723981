import React from "react";

import LocationCreate from "../location-create";

import "./locations-page-header.scss";


const LocationsPageHeader = () => {

    return(
        <div className="page-header locations-page-header">
            <h1>All Locations</h1>
            <LocationCreate />
        </div>
    )
}

export default LocationsPageHeader;