import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom';

import { Pages } from "components/pages";


const LocationRoutes = ({ location }) => {

    const baseRoute = "/locations/";
    const { LocationPages } = Pages;

    return (
        <Switch>
            <Route path={ `${ baseRoute }:locationId/` } exact>
                <LocationPages.Profile
                    location={ location }
                />
            </Route>
            { location.type === "restaurant" &&
                <Route path={`${baseRoute}:locationId/menus`} exact>
                    <LocationPages.Menus
                        location={ location }
                    />
                </Route>
            }
            { location.type === "restaurant" &&
                <Route path={`${baseRoute}:locationId/tables`} exact>
                    <h1 className="color-active">Page in progress</h1>
                </Route>
            }
            { location.type === "hotel" &&
                <Route path={`${baseRoute}:locationId/rooms`} exact>
                    <h1 className="color-active">Page in progress</h1>
                </Route>
            }
            <Route path={ `${ baseRoute }:locationId/visits` }>
                <LocationPages.Visits
                    location={ location }
                />
            </Route>
            <Route path={ `${ baseRoute }:locationId/bookings` } exact>
                <h1 className="color-active">Planed for version 2</h1>
            </Route>
            <Route path="*">
                <Redirect to={ baseRoute } />
            </Route>
        </Switch>
    )
}


export default LocationRoutes;