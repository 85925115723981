import React from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Select } from "antd";

import { LOCATION_CREATE } from "graphql/mutation/location-gql";

import { FloatLabel } from "components/service";
import { errorNotification, successNotification } from "components/request-result";


const { Option } = Select;

const LocationCreateForm = () => {

    const [ form ] = Form.useForm();
    const history = useHistory();

    const [ locationCreateRequest, { loading }] = useMutation( LOCATION_CREATE,
        {
            update(cache, { data }) {

                const {
                    locationCreate : {
                        label,
                        message,
                        location: {
                            id
                        }
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

                history.push(`/locations/${ id }/`)

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    return(
        <Form
            className="location-create-form"
            form={ form }
            onFinish ={ ({type, ...values}) => {
                locationCreateRequest({
                    variables: {
                        type,
                        input: {
                            ...values,
                            status: "draft"
                        }
                    }
                }).catch((error) => {
                    errorNotification(error);
                });
            }}
        >
            <Form.Item
                name="type"
                {...formItemLayout}
                initialValue={ "restaurant" }
                rules={[
                    {
                        required: true,
                        message: 'Field required'
                    }
                ]}
            >
                <FloatLabel label="Location type*">
                    <Select name="location_type">
                        <Option value="restaurant">Restaurant</Option>
                        <Option disabled value="event">Event</Option>
                        <Option disabled value="hotel">Stay</Option>
                    </Select>
                </FloatLabel>
            </Form.Item>

            <Form.Item
                name="title"
                {...formItemLayout}
                rules={[
                    {
                        required: true,
                        message: 'Please input your Last name'
                    }
                ]}
            >
                <FloatLabel label="Location name*">
                    <Input name="location_name" />
                </FloatLabel>
            </Form.Item>
            <div className="form-actions">
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={ loading }
                    disabled={ loading }
                >
                    ABSENDEN
                </Button>
            </div>
        </Form>
    );
}

export default LocationCreateForm;