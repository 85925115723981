import { gql } from "@apollo/client";

export const VISITS_BY_LOCATION_EXPORT = gql`
    mutation VisitsByLocationExport(
        $text: String
        $locationId: ID!
        $orderBy: [MutationVisitsExportOrderByOrderByClause!]
        $where: MutationVisitsExportWhereWhereConditions
    ) {
        visitsExport(
            text: $text
            location_id: $locationId
            orderBy: $orderBy
            where: $where
        ){
            label
            message
            file
        }
    }
`;

export const VISIT_NOTICE_FORM_CREATE = gql`
    mutation NoticeFormCreate(
        $input: NoticeFormCreateOrUpdateInput
    ) {
        noticeFormCreate(
            input: $input
        ){
            label
            message
            noticeForm{
                id
                location_id
                email
                type
                status
                message
                moderator_id
                moderator_notice
                created_at
                updated_at
                moderator{
                    id
                    first_name
                    last_name
                    status
                    role
                }
            }
        }
    }
`;

export const VISIT_NOTICE_FORM_UPDATE = gql`
    mutation NoticeFormUpdate(
        $id: ID!
        $input: NoticeFormCreateOrUpdateInput
    ) {
        noticeFormUpdate(
            id: $id
            input: $input
        ){
            label
            message
            noticeForm{
                id
                location_id
                email
                type
                status
                message
                moderator_id
                moderator_notice
                created_at
                updated_at
                moderator{
                    id
                    first_name
                    last_name
                    status
                    role
                }
            }
        }
    }
`;