import { Skeleton } from "antd";

import LocationVisitsNotice from "../location-visits-notice";
import { tableHelper } from "utils";


const locationVisitsTableBuilder = ({
        loading,
        rowAmount = 15,
        data = [...Array(rowAmount)],
        onlyHeader
    }) => {

    const tableData = data.map( (row = {}, index) => {

        const {
            id,
            first_name, last_name,
            user_id,
            email, phone,
            //number_guests,
            created_at = ""
        } = row;

        const [ date, time ] = created_at?.split(" ");

        return {
            key: id ? id : `row_index_${index}`,
            user : {
                title : "User",
                value :
                    <>
                        <p><b>{ first_name } { last_name }</b></p>
                        <p className="user-type">{ user_id ? "Authorized" : "Guest" }</p>
                    </>,
                columnIndex:'LAST_NAME',
                sorter: true,
                empty:
                    <>
                        <Skeleton.Input className="table-skeleton full" active />
                        <Skeleton.Input className="table-skeleton half" active />
                    </>,
                width: "30%"
            },
            contact : {
                title : "Contact",
                value :
                    <>
                        <p><b>{ email }</b></p>
                        <p>{ phone }</p>
                    </>,
                columnIndex:'EMAIL',
                sorter: true,
                empty:
                    <>
                        <Skeleton.Input className="table-skeleton full" active />
                        <Skeleton.Input className="table-skeleton half" active />
                    </>,
                width: "40%"
            },
            // place : {
            //     title : "Place",
            //     value : ""
            // },
            covid : {
                title : "Covid",
                value : <LocationVisitsNotice
                            visit={ row }
                        />,
                empty : <Skeleton.Button active className="table-skeleton full" />,
                className: "covid-col",
                width: "15%"
            },
            date: {
                title : "Date",
                value :
                    <>
                        <p>{ date }</p>
                        <p>{ time }</p>
                    </>,
                columnIndex:'CREATED_AT',
                sorter: true,
                empty :
                    <>
                        <Skeleton.Input active className="table-skeleton full" />
                        <Skeleton.Input active className="table-skeleton three-quarters" />
                    </>,
                width: "15%"
            }
        };
    });

    return tableHelper.normalize( tableData, loading, onlyHeader );

}

export default locationVisitsTableBuilder;