import { useHistory } from "react-router-dom";

import useVarParam from "./use-var-param";
import { checkUser } from "utils";


const { isAdmin, hasCompany } = checkUser;

const useAuthRedirect = () => {

    const history = useHistory(),
          guestParams = useVarParam("guest");

    return ({ user }) => {

        if(isAdmin(user.role) || !hasCompany(user.company_id)){
            return history.replace("/company/");
        }

        if( guestParams.get("comeFrom") ){
            return  history.replace(guestParams.get("comeFrom"));
        }

        return history.replace("/");
    };
}

export default useAuthRedirect;