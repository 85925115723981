import React from "react";
import { Form, Input } from "antd";

import { FloatLabel } from "components/service";
import { metaFields } from "utils";


const { TextArea } = Input;

const CPGeneralInfo = ({ form, formLayout, company, title, ...props }) => {

    const { normalize, getValue } = metaFields;
    const meta = normalize(company.meta_fields);

    return (
        <>
            { title }
            <Form
                name="general-info"
                form={ form }
                {...formLayout}
                onFinish={
                    ( values ) => {
                        if( props.submitAction) {
                            props.submitAction( values );
                        }
                    }
                }
            >
                <div className="field-group">
                    <Form.Item
                        name="company_name-field"
                        initialValue={ company.title }
                        rules={ [
                            { required: true, message: 'Please input Company name' }
                        ]}
                    >
                        <FloatLabel label="Name der Firma*">
                            <Input name="company_name" />
                        </FloatLabel>
                    </Form.Item>
                    <Form.Item
                        name="business_id-field"
                        initialValue={ company.business_ID }
                        rules={ [
                            { required: true, message: 'Please input Company business ID' }
                        ]}
                    >
                        <FloatLabel label="Geschäfts ID*">
                            <Input name="business_id" />
                        </FloatLabel>
                    </Form.Item>
                </div>
                <Form.Item
                    shouldUpdate
                    name="company_description-string--general"
                    initialValue={ getValue( meta, "company_description" ) }
                >
                    <FloatLabel label="Kurze Firmenbeschreibung">
                        <TextArea
                            rows={3}
                            maxLength={ 200 }
                            showCount={
                                { formatter: ({ count, maxLength }) => maxLength - count}
                            }
                        />
                    </FloatLabel>
                </Form.Item>
            </Form>
        </>
    );
};

export default CPGeneralInfo;