import React from "react";
import { Table } from "antd";

import locationVisitsTableBuilder from "./location-visits-table-builder";
import { tableHelper } from "utils";


const LocationVisitsTable = ({
        data,
        loading,
        pagination,
        className = "",
        setOrderBy,
        defaultOrder
    }) => {

    const { dataSource, columns } = locationVisitsTableBuilder({
        data : !loading && !pagination.total ? [""] : data,
        loading,
        rowAmount : tableHelper.tableRowsCount(pagination),
        onlyHeader: !loading && !data.length
    });

    return(
        <Table
            dataSource={ dataSource }
            columns={ columns }
            onChange={ ( pagination, filters, { column, order } ) => {

                if( column && order ){

                    setOrderBy([
                        {
                            column: column.columnIndex,
                            order: order === "ascend" ? "ASC" : "DESC"
                        }
                    ])

                } else { setOrderBy( [ defaultOrder ] ) }

            }}
            className={ `location-visits-table ${ className }` }
            pagination={ pagination }
        />
    );
};

export default LocationVisitsTable;