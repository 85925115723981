import LocationCreateForm from "./location-create-form";
import LocationUpdateForm from "./location-update-form";
import LocationMenusForm from "./location-menus-form";
import LocationNoticeForm from "./location-notice-form";

const Forms = {
    Create : LocationCreateForm,
    Update : LocationUpdateForm,
    Menus  : LocationMenusForm,
    Notice : LocationNoticeForm
}

export default Forms;