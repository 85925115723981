import LocationPage from "./location-page";
import LocationsPage from "./locations-page";
import locationSubPage from "./sub-page";


const LocationPages = {
    All    : LocationsPage,
    Single : LocationPage,
    ...locationSubPage
}


export default LocationPages;