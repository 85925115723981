
const isAdmin = (role) => {
    return ["admin", "developer"].indexOf(role) !== -1;
}

const hasCompany = ( company ) => {
    return !!company;
}

const hasActiveCompany = (company) => {
    return hasCompany(company) && company.status === "active";
}


const checkUser = {
    isAdmin,
    hasCompany,
    hasActiveCompany,

}


export default checkUser;