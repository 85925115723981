import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Form } from "antd";

import { LOCATION_UPDATE } from "graphql/mutation/location-gql";

import LUForms from "./form-part";
import LocationBanner from "../../location-banner";
import { errorNotification, successNotification } from "components/request-result";
import { metaFields } from "utils";


const formsSubmit = ({ values, updateLocationProfile, location }) => {

    let { metaValues, fieldValues } = metaFields.parseForm(values);

    metaValues = metaFields.filterEmpty(metaValues, location.meta_fields);

    updateLocationProfile({
        variables: {
            id : parseInt(location.id),
            locationInput: {
                title: fieldValues.location_name,
                status: fieldValues.status
            },
            metaInput: [ ...metaValues ]
        }
    })

};

const LocationUpdateForm = ({ location }) => {

    const [ form ] = Form.useForm();

    const [ updateLocationProfile, { loading }] = useMutation( LOCATION_UPDATE,
        {
            update(cache, { data }) {

                const { label, message } = data.locationUpdate;

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    const formParams = {
        form,
        location,
        formLayout : {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        }
    };

    return(
        <div className="form-set">
            <div className="form-wrap">
                <LocationBanner
                    image={ location.preview_banner }
                    locationId={ location.id }
                    classname={ location.status }
                    imageKey={ "preview_banner" }
                />
            </div>
            <div className="form-wrap">
                <h4>General info</h4>
                <LUForms.GeneralInfo { ...formParams } />
            </div>
            <div className="form-wrap">
                <h4>Contact info</h4>
                <LUForms.ContactInfo { ...formParams } />
            </div>
            <div className="form-wrap">
                <h4>Address info</h4>
                <LUForms.AddressInfo
                    submitAction={ ( values ) => formsSubmit(
                        { values, updateLocationProfile, location }
                    )}
                    { ...formParams }
                />
            </div>

            <div className="actions">
                <Button
                    type="primary"
                    loading={ loading }
                    onClick={() => formParams.form.submit()}
                >
                    Save
                </Button>
            </div>
        </div>
    );
}

export default LocationUpdateForm;