import React from "react";
import { Form, Input } from "antd";

import { FloatLabel } from "components/service";
import { metaFields } from "utils";


const LUContactInfo = ({ form, formLayout, location, ...props }) => {

    const { normalize, getValue } = metaFields;
    const meta = normalize(location.meta_fields);

    return (

        <Form
            name="location-contact-info"
            form={form}
            {...formLayout}
            onFinish={
                ( values ) => {
                    if( props.submitAction) {
                        props.submitAction( values );
                    }

                }
            }
        >
            <div className="field-group">
                <Form.Item
                    name="location_email-string--contacts"
                    initialValue={ getValue(meta, "location_email") }
                >
                    <FloatLabel label="E-Mail">
                        <Input name="location_email" />
                    </FloatLabel>
                </Form.Item>
                <Form.Item
                    name="location_site_url-string--contacts"
                    initialValue={ getValue( meta, "location_site_url") }
                >
                    <FloatLabel label="Website">
                        <Input name="location_site_url" />
                    </FloatLabel>
                </Form.Item>
            </div>
            <div className="field-group">
                <Form.Item
                    name="location_phone-string--contacts"
                    initialValue={ getValue( meta, "location_phone") }
                >
                    <FloatLabel label="Phone">
                        <Input name="location_phone" />
                    </FloatLabel>
                </Form.Item>
                <Form.Item
                    name="location_mobile_phone-string--contacts"
                    initialValue={ getValue( meta,  "location_mobile_phone" ) }
                >
                    <FloatLabel label="Mobile phone">
                        <Input name="location_mobile_phone" />
                    </FloatLabel>
                </Form.Item>
            </div>
        </Form>

    );
};

export default LUContactInfo;