import { gql } from '@apollo/client';


export const USER_LOGIN = gql`
    mutation UserLogin($data: UserLoginInput!) {
        userLogin(input: $data){
            access_token
            user{
                id
                first_name
                last_name
                email
                company_id
                phone
                language_slug
                status
                role
                avatar
                updated_at
                created_at
            }
        }
    }
`;

export const USER_REGISTRATION = gql`
    mutation UserRegistration($input: UserRegisterInput) {
        userRegister(input: $input){
            label
            message
        }
    }
`;

export const USER_UPDATE = gql`
    mutation UserUpdate($input: UserUpdateInput!) {
        userUpdate(input: $input){
            label
            message
            user{
                id
                first_name
                last_name
                email
                phone
                language_slug
                status
                role
                avatar
                updated_at
                created_at
            }
        }
    }
`;

export const USER_LOGOUT = gql`
    mutation UserLogout{
        userLogout {
            status
            message
        }
    }
`;

export const CHANGE_PASSWORD = gql`
    mutation ChangePassword($input: UserCurrentChangePassword!) {
        userChangePassword(input: $input){
            label
            message
        }
    }`;

export const FORGOT_PASSWORD = gql`
    mutation UserForgotPassword($input: UserForgotPasswordInput!) {
        userForgotPassword(input: $input){
            label
            message
        }
    }`;

export const CHANGE_FORGOT_PASSWORD = gql`
    mutation ChangeForgotPassword(
        $password: String!
        $token: String!
    ){
        userUpdateForgotPassword(
            password: $password
            token: $token
        ){
            label
            message
        }
    }`;

export const ACTIVATE_ACCOUNT = gql`
    mutation UserActivate( $token: String ){
        userActivate( token: $token ){
            label
            message
        }
    }`;