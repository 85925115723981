import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { GET_LOCATION } from "graphql/query/location-gql";

import { ContainerWrap, TwoColWrap, WrapColumn } from "components/wraps";
import { LocationRoutes } from "components/routes";
import { LocationMenu } from "components/menus";
import { AvatarBlock } from "components/avatar";
import { Loader } from "components/request-result";
import { saveAsFile } from "utils";


const LocationPage = ({ locationId }) => {

    const { data : { location } = { }, loading } = useQuery( GET_LOCATION, {
        variables: {
            id : locationId
        }
    });

    return(
        <ContainerWrap className="page location-page">
            { loading ? <Loader type="block" /> :
                <>
                    { location && location.status !== "block" ?
                        <TwoColWrap>
                            <WrapColumn position="left">
                                <AvatarBlock
                                    size={ 60 }
                                    image={ location.logo }
                                    badge={ <>No logo</>  }
                                    className={ "location-short-info" }
                                >
                                    <h4 className="text">{ location.title }</h4>
                                    <p className="text-type3 color-active">{ location.type }</p>
                                    <p className={ `status ${ location.status }` }>{ location.status }</p>
                                </AvatarBlock>
                                <LocationMenu
                                    locationId={ locationId }
                                    locationType={ location.type }
                                />
                                <div className="location-qr-wrap">
                                    <button
                                        onClick={ () => saveAsFile.image( location.qrcode, "location-qrcode")}
                                    >
                                        <img src={ location.qrcode } alt={ `${location.title} qrcode`}/>
                                    </button>
                                    <p className="text-type3-light">Location QR code</p>
                                </div>
                            </WrapColumn>
                            <WrapColumn position="right">
                                <LocationRoutes location={ location }/>
                            </WrapColumn>
                        </TwoColWrap> :
                        <div className="not-exist">
                            { location && location.status === "block" ?
                                <>
                                    <h1>This location BLOCKED!</h1>
                                    <p>Please contact with platform administrator</p>
                                </> :
                                <h1>Sorry this location doesn't exist!</h1>
                            }
                            <Link to="/locations/" className="link-active" >Go to all locations</Link>
                        </div>
                    }
                </>
            }
        </ContainerWrap>
    )
};

export default LocationPage;