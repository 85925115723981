import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Icons from "components/icons";


const doStep = ({ varParams, startStep = 1, stateName }) => {

    let stateVar = varParams.get(stateName);

    const goStep = (step) => {

        varParams.set({
            [ stateName ] : {
                ...varParams.get(stateName), step
            }
        })
    }

    const prevStep = () => {
        goStep(stateVar.step - 1);
    }

    const nextStep = () => {
        goStep(stateVar.step + 1);
    }

    if(stateVar.step === undefined){
        goStep(startStep);
    }

    return {
        nextStep, prevStep, goStep
    }
}

const Nav = ({ stepAmount, varParams, stateName, backOnFirstStep }) => {

    const history = useHistory();
    const stateVar = varParams.get(stateName);

    const { prevStep } = doStep({ varParams, stateName });


    return(
        <div className="steps-nav-wrap">
            <div className="steps-nav-back">
                {(backOnFirstStep || stateVar.step > 1) &&
                    <button
                        onClick={ () => stateVar.step === 1 ? history.go(-1) : prevStep() }
                    >
                        <Icons.Arrow/>
                    </button>
                }
            </div>
            <ul className="steps-nav">
                { [...Array(stepAmount)].map( ( x, i ) => {
                    let stepClass = "";

                    if(stateVar.step === i + 1 ){
                        stepClass = "active";
                    } else if( i + 1 < stateVar.step ) {
                        stepClass = "finished"
                    }

                    return (
                        <li key={ i } className={ `steps-nav-step ${ stepClass }` }>
                            { i + 1 }
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

const StepsNav = ({
        varParams,
        inMainHeader = false,
        stateName,
        backOnFirstStep = true,
        onNextStep,
        onFinalStep,
        ...props
    }) => {


    useEffect(() => {

        varParams.set({
            headerClass: inMainHeader ? ["logo-off"] : [],
            leftHeaderControl: inMainHeader ?
                <Nav
                    stepAmount={ props.children.length }
                    varParams={ varParams }
                    stateName={ stateName }
                    backOnFirstStep={ backOnFirstStep }
                /> : false
        })

        return () => {
            varParams.set({
                headerClass: [],
                leftHeaderControl: false
            })
        }
    });

    let step = varParams.get(stateName).step;
    const { nextStep } = doStep({ varParams, stateName });

    return (
        <>
            { !inMainHeader &&
                <Nav
                    stepAmount={ props.children.length }
                    varParams={ varParams }
                    stateName={ stateName }
                    backOnFirstStep={ backOnFirstStep }
                />
            }
            { step && React.cloneElement(
                props.children[step - 1], {
                    submitAction: () => {
                        if(onNextStep && onFinalStep) {
                            if (step < props.children.length ) {
                                onNextStep();
                                nextStep();
                            } else {
                                onFinalStep();
                            }
                        } else {
                            nextStep();
                        }
                    }
                }
            )}
        </>
    );

};

export default StepsNav;