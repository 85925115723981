import React from "react";
import { NavLink } from "react-router-dom";


const LocationMenu = ({ locationType = "restaurant", locationId }) => {

    const basePath = `/locations/${ locationId }`;

    return(
        <nav className="location-menu-wrap">
            <ul className="menu location-menu">
                <li>
                    <NavLink to={ `/locations/` } exact>All locations</NavLink>
                </li>
                <li>
                    <NavLink to={ basePath } exact>Profile</NavLink>
                </li>
                { locationType === "restaurant" &&
                    <>
                        <li>
                            <NavLink to={`${ basePath }/menus`} exact>Menus</NavLink>
                        </li>
                        <li>
                            <NavLink to={`${ basePath }/tables`} exact>Tables</NavLink>
                        </li>
                    </>
                }
                { locationType === "hotel" &&
                    <li>
                        <NavLink to={`${ basePath }/rooms`} exact>Rooms</NavLink>
                    </li>
                }
                <li>
                    <NavLink to={`${ basePath }/visits`}>Visits</NavLink>
                </li>
                <li>
                    <NavLink to={`${ basePath }/bookings`} exact>Bookings</NavLink>
                </li>
            </ul>
        </nav>
    );
};


export default LocationMenu;