import React from "react";
import { Avatar } from "antd";

//import Icons from "components/icons";
import { Loader } from "components/request-result";

import "./avatar-image.scss";


const AvatarImage = ({
         photoUrl,
         badge,
         size = 40,
         loading,
         shape = 'circle'
    }) => {

    let photo = photoUrl,
        extraClass = "";

    if(photoUrl && photoUrl.length > 0){
        photo  = photoUrl;
        extraClass = "with-image";
    } else {
        if(!badge){
            photo = <>No image</>;
            extraClass = "no-image";
        }
    }

    return (
        <div className={`avatar-image ${extraClass}`}>
            <Avatar
                src={ !loading && photo }
                size={size}
                shape={ shape }
            >
                { loading ? <Loader /> : badge}
            </Avatar>
        </div>
    );
};

export default AvatarImage;